import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const Container = ({children}) => {
  return (
    <div className={clsx("container")}>
      { children }
    </div>
  );
};

Container.propTypes = {
  children: PropTypes.node,
};

export default Container;