import React, { useState, useRef }  from 'react';
import clsx from 'clsx';
import Slideshow from './Slideshow';

const Project = (props) => {
  const {
    color, 
    date, 
    title,
    html, 
    pictures, 
    col
  } = props;

  const [setActive, setActiveState] = useState("");
  const [setHeight, setHeightState] = useState("0px");
  const content = useRef(null);

  const toggleAccordion = () => {
    setActiveState(setActive === "" ? "active" : "");
    setHeightState(
      setActive === "active"
        ? "0px"
        : `${content.current.scrollHeight}px`
    );
  }

  return ( 
    <article 
      className={clsx(
        "project-container", 
        `project-container--${color}`,
        `project-container-col--${col}`,
        )}
    >
      <Slideshow 
        pictures={pictures}
        title={title}
      />
      <div className="project-container-infos">
        <div className="project-container-infos-mini">
          <h2>{title}</h2><p>{date}</p>
        </div>
        <button 
          className='desc-button'
          onClick={toggleAccordion}
        >i
        </button>
      </div>
        <div
          className={`desc-wrapper`}
          style={{ height: `${setHeight}` }}
        >
          <div dangerouslySetInnerHTML={{ __html: html }} ref={content} className='desc-text'/>
        </div>
    </article>
  );
};

export default Project;
