import React from 'react';
import Img from 'gatsby-image';
import clsx from 'clsx';
import Slider from "react-slick";

import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';

const SampleArrow = (props) => {
  const { type, onClick } = props;
  return (
    <div
    className={clsx(type === "next" ? "arrow-next" : "arrow-prev" , "arrow")}
      onClick={onClick}
    />
  );
};

const settings = {
  dots: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <SampleArrow type="next"/>,
  prevArrow: <SampleArrow type="prev"/>
};

const Slideshow = (props) => {
  const { pictures, title } = props;

  return(
      <div className="slide-container">
        <Slider {...settings}
        >
          {pictures.map((node, index)=> 
          <div key={node.childImageSharp.fluid.src} 
          >
            <Img
                fluid={node.childImageSharp.fluid}
                key={node.childImageSharp.fluid}
                alt={`${title}-${index}`}
              />
            </div>
          )}
          </Slider>
    </div>
  );
};

export default Slideshow;
