import React from 'react';
import Layout from 'components/Layout';
import Container from 'components/Container';
import Projects from 'components/Projects';

const IndexPage = () => {
  return (
    <Layout pageName="Projets">
      <Container>
        <Projects />
      </Container>
    </Layout>
  );
};

export default IndexPage;
