import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import clsx from 'clsx';
import Project from './Project';

const Projects = () => {
  const data = useStaticQuery(graphql`
    {
      allMarkdownRemark(
        sort: {
          fields: frontmatter___num,
          order: DESC
        }
        filter: {
          fileAbsolutePath: {
            regex: "/content/projects/"
          }
        }
      ) {
        edges {
          node {
            id
            frontmatter {
              pictures {
                childImageSharp {
                  fluid(maxWidth: 1500, quality: 100){
                    ...GatsbyImageSharpFluid
                  }
                }
                absolutePath
              }
              title
              description
              date
              color
              col
            }
            html
          }
        }
      }
    }
  `);
  const projects = data.allMarkdownRemark.edges; 
  return (
    <div
      className={clsx("projects-container")}
    >
{projects.map(({ 
          node: {
            id, 
            frontmatter : { 
              title, 
              description,
              pictures,
              color, 
              date, 
              col,
            },
            html,
          }
        }) => 
          <Project
            key={id}
            color={color}
            title={title}
            date={date}
            col={col}
            pictures={pictures}
            html={html}
          />          
        )}
      </div>
  );
}

export default Projects;
